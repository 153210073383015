import React from 'react';
import {
  CloudIcon,
  FogIcon,
  HailCloudIcon,
  LightningCloudIcon,
  LightningStormIcon,
  CloudSunIcon,
  CloudMoonIcon,
  RainCloudIcon,
  SnowCloudIcon,
  SleetCloudIcon,
  SunIcon,
  MoonIcon,
  WindIcon
} from './icons';

export const WeatherIcon = ({condition, size = 36}) => {
	let Icon;
	switch (condition) {
		case 'clear-day':
      Icon = SunIcon;
      break;
    case 'clear-night':
      Icon = MoonIcon;
			break;
		case 'partly-cloudy-day':
      Icon = CloudSunIcon;
      break;
    case 'partly-cloudy-night':
			Icon = CloudMoonIcon;
			break;
		case 'cloudy':
			Icon = CloudIcon;
			break;
		case 'fog':
			Icon = FogIcon;
			break;
		case 'hail':
			Icon = HailCloudIcon;
			break;
		case 'lightning':
			Icon = LightningCloudIcon;
			break;
		case 'thunderstorm':
			Icon = LightningStormIcon;
			break;
		case 'rain':
			Icon = RainCloudIcon;
			break;
		case 'snow':
			Icon = SnowCloudIcon;
			break;
		case 'sleet':
			Icon = SleetCloudIcon;
			break;
    case 'wind':
      Icon = WindIcon;
      break;
		default:
			console.warn(`Unknown weather condition: ${condition}`);
			// Fall back to generic weather icon
			Icon = CloudIcon;
			break;
	}
	return <Icon size={size}/>;
};
