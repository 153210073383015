import React, {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import {formatTime} from './date-util';
import {TrainIcon, OresundstagIcon, BusIcon} from './icons';
import {DepartureDeviation, DepartureCanceled} from './format';
import './transportation.css';

const ScrollTop = {
  top: 0,
  left: 0,
  behavior: 'smooth',
};

export const TransportationPage = React.memo(React.forwardRef((props, ref) => {
  const {transportation: {trains, buses}} = props;

  const [expandedList, setExpandedList] = useState('all');
  const trainList = useRef();
  const busList = useRef();

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (trainList.current) {
        trainList.current.scrollTo(ScrollTop);
      }
      if (busList.current) {
        busList.current.scrollTo(ScrollTop);
      }
      setExpandedList('all');
    },
  }), []);

  const expandList = useCallback(list => {
    setExpandedList(current => list === current ? 'all' : list);
  }, []);

  return (
    <div
      className={classNames(
        'transportation',
        `transportation--${expandedList}`
      )}
    >
      <h2
        className='transportation__header'
        onClick={() => expandList('trains')}
      >
        <TrainIcon size={48}/>
        Tåg
      </h2>
      <ul
        className='transportation__list transportation__list--trains'
        ref={trainList}
      >
        {trains.map((train, index) =>
          <Train key={index} details={train}/>
        )}
      </ul>
      <h2
        className='transportation__header'
        onClick={() => expandList('buses')}
      >
        <BusIcon size={48}/>
        Bussar
      </h2>
      <ul
        className='transportation__list transportation__list--buses'
        ref={busList}
      >
        {buses.map((bus, index) =>
          <Bus key={index} details={bus}/>
        )}
      </ul>
    </div>
  );
}));

const SeverityClass = {
  none: null,
  minor: 'transportation__item--severity-minor',
  major: 'transportation__item--severity-major',
  passed: 'transportation__item--severity-passed',
};

const CanceledText = '\u00D7';

const Train = ({details}) => {
  const outerClassName = classNames(
    'transportation__item',
    'transportation__item--train',
    SeverityClass[details.delaySeverity],
    {
      'transportation__item--delayed': !!details.expectedTime,
      'transportation__item--canceled': details.canceled,
    }
  );
  return (
    <li className={outerClassName}>
      <DepartureTime
        scheduled={details.scheduledTime}
        expected={details.expectedTime}
        canceled={details.canceled}
      />
      <MeansOfTransport type={details.trainType}/>
      <div className='transportation__towards'>
        {details.towards}
      </div>
      {(details.track || details.canceled) &&
        <div className='transportation__point'>
          {details.canceled ? CanceledText : details.track}
        </div>
      }
    </li>
  );
};

const Bus = ({details}) => {
  const outerClassName = classNames(
    'transportation__item',
    'transportation__item--bus',
    SeverityClass[details.delaySeverity],
    {
      'transportation__item--delayed': !!details.expectedTime,
      'transportation__item--canceled': details.canceled,
    }
  );
  return (
    <li className={outerClassName}>
      <DepartureTime
        scheduled={details.scheduledTime}
        expected={details.expectedTime}
        canceled={details.canceled}
      />
      <MeansOfTransport
        type={details.busType}
        busNumber={details.busNumber}
      />
      <div className='transportation__towards'>
        {details.towards}
      </div>
      {(details.point || details.canceled) &&
        <div className='transportation__point'>
          {details.canceled ? CanceledText : details.point}
        </div>
      }
    </li>
  );
};

const DepartureTime = ({scheduled, expected, canceled}) => {
  let deviation = null;
  if (canceled) {
    deviation =
      <span className='transportation__time-deviation'>
        {DepartureCanceled}
      </span>;
  } else if (expected) {
    deviation =
      <span className='transportation__time-deviation'>
        {DepartureDeviation(Math.floor((expected - scheduled) / 60000))}
      </span>;
  }
  return (
    <div
      className={classNames('transportation__time', {
        'transportation__time--deviation': !!deviation,
      })}
    >
      <span className='transportation__scheduled-time'>
        {formatTime(scheduled)}
      </span>
      {deviation}
    </div>
  );
};

const MeansOfTransportClass = {
  'Öresundståg': 'transportation__means--oresundstag',
  'Pågatågen': 'transportation__means--pagatag',
  'Pågatåg Express': 'transportation__means--pagatag-express',
  'Stadsbuss': 'transportation__means--city-bus',
  'Regionbuss': 'transportation__means--regional-bus',
};

const MeansOfTransport = ({type, busNumber}) => {
  let inner = null;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'Öresundståg':
      inner = <OresundstagIcon/>;
      break;
    case 'Pågatågen':
    case 'Pågatåg Express':
      inner = <TrainIcon/>;
      break;
    case 'Stadsbuss':
    case 'Regionbuss':
      inner = String(busNumber);
      break;
  }
  return (
    <div
      className={classNames(
        'transportation__means',
        MeansOfTransportClass[type]
      )}
    >
      <span className='transportation__number'>
        {inner}
      </span>
    </div>
  );
};
