export const MonthsLong = Object.freeze([
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
]);

export const MonthsShort = Object.freeze([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
]);

export const WeekdaysLong = Object.freeze([
  'Söndag',
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
]);

export const WeekdaysShort = Object.freeze([
  'Sön',
  'Mån',
  'Tis',
  'Ons',
  'Tor',
  'Fre',
  'Lör',
]);

export const WeekdaysLetter = Object.freeze([
  'S',
  'M',
  'T',
  'O',
  'T',
  'F',
  'L',
]);

export const DepartureDeviation = minutes =>
  minutes < 0 ? DepartureEarly(-minutes) : DepartureLate(minutes);

export const DepartureLate = minutes => `Sen ${minutes} min`;

export const DepartureEarly = minutes => `Tidig ${minutes} min`;

export const DepartureCanceled = 'Inställd';
