import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import fscreen from 'fscreen';
import {isSameDate} from './date-util';
import {Clock} from './clock';
import {WeatherPage, WeatherSummary} from './weather';
import {TransportationPage} from './transportation';
import {CalendarPage} from './calendar';
import {
  TrainIcon,
  BusIcon,
  CalendarIcon,
  FullscreenIcon
} from './icons';
import {WeatherService} from './weather-service';
import {TransportationService} from './transportation-service';
import {Intervals} from './intervals';
import './app.css';

export class App extends Component {
  tickId = null;
  weatherPage = null;
  transportationPage = null;
  calendarPage = null;
  lastWeatherUpdate = 0;
  weatherService = new WeatherService();
  lastTransportationUpdate = 0;
  transportationService = new TransportationService();

  sections = [
    {
      ref: React.createRef(),
      name: 'weather',
      content: ({weather}, ref) =>
        <WeatherPage weather={weather} ref={ref}/>,
      switcher: ({weather}) => <WeatherSummary weather={weather}/>,
      activate: ref => ref.current.reset(),
    },
    {
      ref: React.createRef(),
      name: 'transportation',
      content: ({transportation}, ref) =>
        <TransportationPage transportation={transportation} ref={ref}/>,
      switcher: ({transportation}) => {
        const {trains, buses} = transportation;
        const trainHealth = getTransportationHealth(trains);
        const busHealth = getTransportationHealth(buses);
        return (
          <Fragment>
            <span className={`app__transportation-health--${trainHealth}`}>
              <TrainIcon/>
            </span>
            +
            <span className={`app__transportation-health--${busHealth}`}>
              <BusIcon/>
            </span>
          </Fragment>
        );
      },
      activate: ref => ref.current.reset(),
    },
    {
      ref: React.createRef(),
      name: 'calendar',
      content: ({today}, ref) => <CalendarPage today={today} ref={ref}/>,
      switcher: () => <CalendarIcon/>,
      activate: ref => ref.current.reset(),
    },
  ];

  constructor(props) {
    super(props);

    const now = new Date();
    this.state = {
      section: Intervals.getDefaultSection(now),

      now: now,
      today: now,

      weather: {
        condition: 'cloudy',
        conditionText: 'Laddar...',
        currentTemp: 0,
        precipitationChance: 0,
        humidity: 0,
        windSpeed: 0,
        windAngle: 0,
        hourly: [],
        sunTimes: [],
      },

      transportation: {
        trains: [],
        buses: [],
      },
    };

    this.lastActivity = now;
  }

  componentDidMount() {
    this.tickId = window.setInterval(this.handleTick, 999);

    fscreen.addEventListener(
      'fullscreenchange',
      this.handleFullscreenChange,
      false
    );

    IdleEvents.forEach(event => {
      window.addEventListener(event, this.handleActivity, {passive: true});
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);

    fscreen.removeEventListener(
      'fullscreenchange',
      this.handleFullscreenChange,
      false
    );

    IdleEvents.forEach(event => {
      window.removeEventListener(event, this.handleActivity, {passive: true});
    });
  }

  handleTick = () => {
    const now = new Date();

    const nextState = {now};
    if (!isSameDate(now, this.state.today)) {
      nextState.today = now;
    }

    if (this.lastActivity <= now - Intervals.idleTimeout) {
      nextState.section = Intervals.getDefaultSection(now);
      // Reset scroll position etc. for the new section
      const newSection = this.sections.find(s => s.name === nextState.section);
      newSection.activate(newSection.ref);
      // No need to keep updating every tick
      this.lastActivity = now;
    }

    this.setState(nextState);

    const weatherUpdateFreq = Intervals.getWeatherUpdateFrequency(now);
    if (this.lastWeatherUpdate <= now - weatherUpdateFreq) {
      this.weatherService.update()
        .then(weather => this.setState({weather}))
        .catch(e => {
          console.error(`Error fetching weather: ${e.message}`);
        });
      this.lastWeatherUpdate = now.getTime();
    }

    const transportationUpdateFreq = Intervals.getTransportationUpdateFrequency(now);
    if (this.lastTransportationUpdate <= now - transportationUpdateFreq) {
      this.transportationService.update()
        .then(transportation => this.setState({transportation}))
        .catch(e => {
          console.error(`Error fetching departures: ${e.message}`);
        });
      this.lastTransportationUpdate = now.getTime();
    }
  };

  handleActivity = () => {
    this.lastActivity = Date.now();
  };

  handleSwitchSection = section => {
    const newSection = this.sections.find(s => s.name === section);
    if (newSection) {
      newSection.activate(newSection.ref);
    }
    this.setState({section});
  };

  handleToggleFullscreen = () => {
    if (fscreen.fullscreenElement === null) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
  };

  handleFullscreenChange = () => {
    this.forceUpdate();
  };

  render() {
    const {section, now} = this.state;
    const {sections} = this;
    const sectionIndex = sections.findIndex(s => s.name === section);

    return (
      <div
        className={classNames('app', {
          'app--dev': process.env.NODE_ENV === 'development',
          'app--fullscreen': fscreen.fullscreenElement !== null,
        })}
      >
        <div
          className='app__clock'
          onClick={() => this.handleSwitchSection('calendar')}
        >
          <Clock now={now}/>
        </div>

        <div className='app__main'>
          {sections.map(({name, content, ref}, index) =>
            <div
              key={name}
              className='app__section'
              style={{
                left: `${100 * (index - sectionIndex)}%`,
              }}
            >
              {content(this.state, ref)}
            </div>
          )}
        </div>

        <div className='app__sections'>
          {sections.map(({name, switcher}) =>
            <SectionSwitch
              key={name}
              active={section === name}
              onClick={() => this.handleSwitchSection(name)}
            >
              {switcher(this.state)}
            </SectionSwitch>
          )}
        </div>

        {fscreen.fullscreenEnabled &&
          fscreen.fullscreenElement === null &&
          <button
            className='app__fullscreen-toggle'
            onClick={this.handleToggleFullscreen}
          >
            <FullscreenIcon/>
          </button>}
      </div>
    );
  }
}

const IdleEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
];

const SectionSwitch = ({active = false, onClick, children}) =>
  <button
    className={classNames('app__section-switch', {
      'app__section-switch--active': active,
    })}
    onClick={onClick}
  >
    {children}
  </button>;

function getTransportationHealth(departures) {
  let noneCount = 0;
  let minorCount = 0;
  let majorCount = 0;

  const max = Math.min(10, departures.length);
  for (let i = 0; i < max; i++) {
    const departure = departures[i];
    switch (departure.delaySeverity) {
      case 'none':
        noneCount++;
        break;
      case 'minor':
        minorCount++;
        break;
      case 'major':
        majorCount++;
        break;
      case 'passed':
        // If the disruption has passed, we count it as a minor disruption if
        // it was a delay, and as no disruption otherwise. Many passed late
        // departures are probably a sign of unhealthy traffic conditions.
        if (departure.expectedTime > departure.scheduledTime) {
          minorCount++;
        } else {
          noneCount++;
        }
        break;
      default:
        console.warn(`Unknown severity: ${departure.delaySeverity}`);
        break;
    }
  }

  const disruptionCount = minorCount + majorCount;
  if (disruptionCount > 0) {
    // If there are more major disruptions than any other category, that qualifies
    // as very poor health.
    if (majorCount >= minorCount && majorCount >= noneCount) {
      return 'severe';
    }
    // If at least half of the departures are disrupted, the health is not great,
    // but probably not catastrophic.
    if (majorCount + minorCount >= noneCount) {
      return 'poor';
    }
  }
  return 'ok';
}
