export const isSameMonth = (d1, d2) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth();

export const isSameDate = (d1, d2) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate();

export const isoWeek = date => {
  const res = new Date(date.valueOf());
  const day = (date.getDay() + 6) % 7;
  res.setDate(res.getDate() - day + 3);
  const first = new Date(res.getFullYear(), 0, 4);
  const days = (res - first) / 86400000;
  return 1 + Math.ceil(days / 7);
};

export const formatTime = date =>
  `${padZero2(date.getHours())}:${padZero2(date.getMinutes())}`;

export const padZero2 = time => {
  switch (time) {
    case 0: return '00';
    case 1: return '01';
    case 2: return '02';
    case 3: return '03';
    case 4: return '04';
    case 5: return '05';
    case 6: return '06';
    case 7: return '07';
    case 8: return '08';
    case 9: return '09';
    default: return `${time}`;
  }
};
