import React, {useRef, useImperativeHandle} from 'react';
import classNames from 'classnames';
import {WeatherIcon} from './weather-icon';
import {
  UmbrellaIcon,
  CompassIcon,
  HumidityIcon
} from './icons';
import {Forecast} from './forecast';
import './weather.css';

const ScrollTop = {
  top: 0,
  left: 0,
  behavior: 'smooth',
};

export const WeatherPage = React.memo(React.forwardRef(({weather}, ref) => {
  const mainContainer = useRef();

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (mainContainer.current) {
        mainContainer.current.parentNode.scrollTo(ScrollTop);
      }
    },
  }), []);

  return (
    <div className='weather' ref={mainContainer}>
      <div className='weather__current'>
        <div className='weather__icon'>
          <WeatherIcon
            condition={weather.condition}
            size={120}
          />
        </div>
        <div className='weather__temperature'>
          {formatTemperature(weather.currentTemp)}
          <span className='weather__temperature-unit'>
            &nbsp;°C
          </span>
        </div>
        <div className='weather__details'>
          <div className='weather__detail'>
            <CompassIcon angle={weather.windAngle + 180}/>
            {Math.round(weather.windSpeed)}&nbsp;m/s
          </div>
          <div className='weather__detail'>
            <UmbrellaIcon/>
            {weather.precipitationChance}&nbsp;%
          </div>
          <div className='weather__detail'>
            <HumidityIcon/>
            {weather.humidity}&nbsp;%
          </div>
        </div>
      </div>
      <div className='weather__condition'>
        {weather.conditionText}
      </div>
      <div className='weather__forecast'>
        <Forecast weather={weather}/>
      </div>
    </div>
  );
}));

export const WeatherSummary = React.memo(props => {
  const {weather, className} = props;
  return (
    <span className={classNames('weather-summary', className)}>
      <span className='weather-summary__detail'>
        <WeatherIcon condition={weather.condition}/>
        {formatTemperature(weather.currentTemp)}
        <span className='weather-summary__unit'>°C</span>
      </span>

      <span className='weather-summary__detail'>
        <UmbrellaIcon/>
        {Math.round(weather.precipitationChance)}
        <span className='weather-summary__unit'>%</span>
      </span>

      <span className='weather-summary__detail'>
        <CompassIcon angle={weather.windAngle + 180}/>
        {Math.round(weather.windSpeed)}
        <span className='weather-summary__unit'>m/s</span>
      </span>
    </span>
  );
});

function formatTemperature(degrees) {
  const degreesInt = Math.round(degrees);
  if (degreesInt < 0) {
    return `\u2212${Math.abs(degreesInt)}`;
  } else {
    return `${degreesInt}`;
  }
}
