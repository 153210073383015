const URL = 'https://info-screen.heurl.in/api/departures.php';

export class TransportationService {
  constructor() {
    this.requestNumber = 0;
    this.parseDates = this.parseDates.bind(this);
  }

  update() {
    const requestNumber = ++this.requestNumber;
    return fetch(URL)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }
        if (this.requestNumber !== requestNumber) {
          throw new Error('Interrupted by another request');
        }
        return this.parse(data);
      });
  }

  parse(data) {
    return {
      trains: data.trains.map(this.parseDates),
      buses: data.buses.map(this.parseDates),
    };
  }

  parseDates(departure) {
    return {
      ...departure,
      scheduledTime: new Date(departure.scheduledTime),
      expectedTime: departure.expectedTime && new Date(departure.expectedTime),
    };
  }
}
