import React, {useState, useCallback, useImperativeHandle} from 'react';
import classNames from 'classnames';
import {isSameMonth, isSameDate, isoWeek} from './date-util';
import {ChevronLeftIcon, ChevronRightIcon} from './icons';
import {MonthsLong, MonthsShort, WeekdaysLetter} from './format';
import './calendar.css';

const FirstDayOfWeek = 1; // Monday

export const CalendarPage = React.memo(React.forwardRef((props, ref) => {
  const {today} = props;

  const [month, setMonth] = useState(today);
  const [view, setView] = useState('month');

  useImperativeHandle(ref, () => ({
    reset: () => {
      setMonth(today);
      setView('month');
    },
  }), [today]);

  const handleChangePeriod = useCallback((delta) => {
    const newDate = new Date(month.getTime());
    switch (view) {
      case 'month':
        newDate.setMonth(newDate.getMonth() + delta);
        break;
      case 'year':
        newDate.setFullYear(newDate.getFullYear() + delta);
        break;
      default:
        console.warn(`Invalid view: ${view}`);
        break;
    }
    setMonth(newDate);
  }, [month, view]);

  const handleChangeView = useCallback(() => {
    setView('year');
  }, []);

  const handleClickMonth = useCallback((newYear, newMonth) => {
    const newDate = new Date(month.getTime());
    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);
    setMonth(newDate);
    setView('month');
  }, [month]);

  return (
    <div className='calendar'>
      <PeriodPicker
        onPrev={() => handleChangePeriod(-1)}
        onNext={() => handleChangePeriod(1)}
        onClick={handleChangeView}
      >
        {view === 'month' && MonthsLong[month.getMonth()]}
        {' '}
        {month.getFullYear()}
      </PeriodPicker>

      <DayTable
        month={month}
        today={today}
        visible={view === 'month'}
      />
      <MonthTable
        year={month.getFullYear()}
        today={today}
        visible={view === 'year'}
        onClickMonth={handleClickMonth}
      />
    </div>
  );
}));

const PeriodPicker = ({className, children, onPrev, onNext, onClick}) =>
  <div className={classNames('calendar__period-picker', className)}>
    <button
      className='calendar__period calendar__period--prev'
      onClick={onPrev}
    >
      <ChevronLeftIcon size={48}/>
    </button>
    <button
      className='calendar__period calendar__period--current'
      onClick={onClick}
    >
      {children}
    </button>
    <button
      className='calendar__period calendar__period--next'
      onClick={onNext}
    >
      <ChevronRightIcon size={48}/>
    </button>
  </div>;

const DayTable = React.memo(props => {
  const {today, month, visible = true} = props;

  const startDate = new Date(
    month.getFullYear(),
    month.getMonth(),
    1
  );
  // Find the nearest preceding FirstDayOfWeek.
  const startDayOfWeek = (startDate.getDay() - FirstDayOfWeek + 7) % 7;
  if (startDayOfWeek !== 0) {
    startDate.setDate(startDate.getDate() - startDayOfWeek);
  }

  const weeks = [];
  const currentDate = new Date(startDate.getTime());
  for (let w = 0; w < 6; w++) {
    const weekNumber = isoWeek(currentDate);
    const days = [];
    for (let d = 0; d < 7; d++) {
      days.push(
        <td
          key={d}
          className={classNames('calendar__day', {
            'calendar__day--today': isSameDate(currentDate, today),
            'calendar__day--other-month': !isSameMonth(currentDate, month),
          })}
        >
          {currentDate.getDate()}
        </td>
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    weeks.push(
      <tr key={weeks.length}>
        <th className='calendar__week-number'>
          {weekNumber}
        </th>
        {days}
      </tr>
    );
  }

  return (
    <table
      className={classNames('calendar__month', {
        'calendar__month--hidden': !visible,
      })}
    >
      <tbody>
        <tr>
          <th className='calendar__week-number'>v</th>
          <th>{WeekdaysLetter[FirstDayOfWeek]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 1) % 7]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 2) % 7]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 3) % 7]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 4) % 7]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 5) % 7]}</th>
          <th>{WeekdaysLetter[(FirstDayOfWeek + 6) % 7]}</th>
        </tr>
        {weeks}
      </tbody>
    </table>
  );
});

const MonthLayout = [
  [0, 1, 2, 3],
  [4, 5, 6, 7],
  [8, 9, 10, 11],
];

const MonthTable = React.memo(props => {
  const {year, today, visible = true, onClickMonth} = props;

  return (
    <table
      className={classNames('calendar__year', {
        'calendar__year--hidden': !visible,
      })}
    >
      <tbody>
        {MonthLayout.map((row, index) =>
          <tr key={index}>
            {row.map(month =>
              <td
                key={month}
                className={classNames('calendar__year-month', {
                  'calendar__year-month--current':
                    today.getFullYear() === year &&
                    today.getMonth() === month
                })}
              >
                <button onClick={() => onClickMonth(year, month)}>
                  {MonthsShort[month]}
                </button>
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
});
