import React, {Fragment} from 'react';
import classNames from 'classnames';
import {formatTime} from './date-util';
import {IconColors, SunriseIcon, SunsetIcon} from './icons';
import {WeatherIcon} from './weather-icon';
import {WeekdaysLong} from './format';
import './forecast.css';

const ItemHeight = 50;

const HourInMilliseconds = 3600000;

export const Forecast = React.memo(({weather}) => {
  const {hourly} = weather;

  if (hourly.length === 0) {
    return null;
  }
  const {minHour, maxHour, minTemp, maxTemp} = findExtremes(hourly);
  const totalMillis = maxHour - minHour;
  const totalHours = totalMillis / HourInMilliseconds;

  const sunTimes = weather.sunTimes.filter(sunTime =>
    sunTime.time >= minHour && sunTime.time <= maxHour
  );

  const getLabelY = time => {
    const hourOffset = (time - minHour) / HourInMilliseconds;
    return `${(ItemHeight / 2) + Math.round(ItemHeight * hourOffset)}px`;
  };

  return (
    <div className='forecast'>
      <div
        className='forecast__list'
        style={{height: `${ItemHeight * (totalHours + 1)}px`}}
      >
        <span className='forecast__gutter'/>
        <TemperatureChart
          hourly={hourly}
          minTemp={minTemp}
          maxTemp={maxTemp}
        />
        <HourlyForecast
          hourly={hourly}
          minTemp={minTemp}
          maxTemp={maxTemp}
          getLabelY={getLabelY}
        />
        <SunTimes
          sunTimes={sunTimes}
          getLabelY={getLabelY}
        />
      </div>
    </div>
  );
});

const HourlyForecast = ({hourly, minTemp, maxTemp, getLabelY}) => {
  const deltaTemp = maxTemp - minTemp;

  const lastIndex = hourly.length - 1;
  let lastCondition = null;
  return hourly
    .map((hour, index) => {
      let time = null;
      if (hour.time.getHours() % 3 === 0) {
        time =
          <Label type='time' y={getLabelY(hour.time)}>
            {formatTime(hour.time)}
          </Label>;
      }

      let weatherIcon = null;
      if (lastCondition === null || hour.condition !== lastCondition) {
        weatherIcon =
          <Label type='condition' y={getLabelY(hour.time)}>
            <WeatherIcon condition={hour.condition}/>
          </Label>;
        lastCondition = hour.condition;
      }

      let dateBreak = null;
      if (hour.time.getHours() === 0 && index !== 0 && index !== lastIndex) {
        dateBreak =
          <DateBreak time={hour.time} y={getLabelY(hour.time)}/>;
      }

      let temperature = null;
      if (hour.time.getHours() % 3 === 0) {
        const tempX = (Math.round(hour.temperature) - minTemp) / deltaTemp;
        temperature =
          <Label
            type='temperature'
            x={`${Math.round(40 + 160 * tempX)}px`}
            y={getLabelY(hour.time)}
          >
            {formatTemperature(hour.temperature)}°
          </Label>
      }

      if (!time && !weatherIcon && !dateBreak) {
        return null;
      }

      return (
        <Fragment key={index}>
          {time}
          {dateBreak}
          {weatherIcon}
          {temperature}
        </Fragment>
      );
    });
};

// Not the newspaper, but a list of sunrise/sunset times.
const SunTimes = ({sunTimes, getLabelY}) =>
  sunTimes
    .map((sunTime, index) =>
      <Label
        key={index}
        type='sun'
        y={getLabelY(sunTime.time)}
      >
        <SunIcon type={sunTime.type}/>
        {formatTime(sunTime.time)}
      </Label>
    );

const Label = ({
  type = null,
  className = null,
  x = null,
  y = '0px',
  children
}) =>
  <div
    className={classNames(
      'forecast__label',
      type && `forecast__label--${type}`,
      className
    )}
    style={{top: y, left: x}}
  >
    {children}
  </div>;

const SunIcon = ({type}) => {
  let Icon;
  switch (type) {
    case 'sunrise':
      Icon = SunriseIcon;
      break;
    case 'sunset':
      Icon = SunsetIcon;
      break;
    default:
      throw new Error(`Invalid sun time type: ${type}`);
  }

  return <Icon color={IconColors.Sun}/>;
};

const DateBreak = ({time, y = '0px'}) =>
  <div
    className='forecast__date-break'
    style={{top: y}}
  >
    <div className='forecast__day-before'>
      {WeekdaysLong[(time.getDay() + 6) % 7]}
    </div>
    <div className='forecast__day-after'>
      {WeekdaysLong[time.getDay()]}
    </div>
  </div>;

const TemperatureChart = ({hourly, minTemp, maxTemp}) => {
  const deltaTemp = maxTemp - minTemp;

  const temperaturePoints = hourly
    .map(({temperature}, index) => {
      const x = (Math.round(temperature) - minTemp) / deltaTemp;
      return `${Math.round(40 + 160 * x)},${ItemHeight * index}`;
    });
  const temperaturePath = temperaturePoints.join('L');

  const height = ItemHeight * (hourly.length - 1);
  return (
    <svg
      className='forecast__chart'
      viewBox={`0 0 202 ${height}`}
      preserveAspectRatio='none'
      width='202'
      height={height}
    >
      <path
        className='forecast__chart-fill'
        d={`M0,0L${temperaturePath}L0,${height}Z`}
      />
      <path
        className='forecast__chart-line'
        d={`M${temperaturePath}`}
      />
    </svg>
  );
};

function findExtremes(hourly) {
  let minTemp = null;
  let maxTemp = null;
  hourly.forEach(({temperature}) => {
    const temperatureInt = Math.round(temperature);
    if (minTemp === null || temperatureInt < minTemp) {
      minTemp = temperatureInt;
    }
    if (maxTemp === null || temperatureInt > maxTemp) {
      maxTemp = temperatureInt;
    }
  });

  return {
    minTemp,
    maxTemp,
    minHour: hourly[0].time,
    maxHour: hourly[hourly.length - 1].time,
  };
}

function formatTemperature(degrees) {
  const degreesInt = Math.round(degrees);
  if (degreesInt < 0) {
    return `\u2212${Math.abs(degreesInt)}`;
  } else {
    return `${degreesInt}`;
  }
}
