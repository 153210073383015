import React from 'react';

export const IconColors = Object.freeze({
  Cloud: '#ddd',
  Rain: '#2196f3',
  Bolt: '#ffeb3b',
  Snow: '#fff',
  Fog: '#666',
  Sun: '#ffb74d',
  Moon: '#b0bec5',
});

export const TrainIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M18,10H6V5H18M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M4,15.5A3.5,3.5 0 0,0 7.5,19L6,20.5V21H18V20.5L16.5,19A3.5,3.5 0 0,0 20,15.5V5C20,1.5 16.42,1 12,1C7.58,1 4,1.5 4,5V15.5Z'
    />
  </svg>
);

export const OresundstagIcon = React.memo(({
  size = 36,
  color = 'currentColor',
}) =>
  <svg width={size} height={size} viewBox='-3 13 70 70'>
    <path
      fill={color}
      d='M16.5,47.3c0-8.6,6.9-15.5,15.4-15.5c2.3,0,4.5,0.6,6.4,1.4l12-12c-5.2-3.7-11.5-5.8-18.4-5.8C14.3,15.4,0,29.7,0,47.3c0,6.9,2.2,13.2,5.8,18.4l12-12C17,51.8,16.5,49.6,16.5,47.3L16.5,47.3z M46.1,41c0.9,1.9,1.4,4.1,1.4,6.3c0,8.6-6.9,15.5-15.5,15.5c-2.2,0-4.4-0.5-6.3-1.4l-12,12c5.2,3.7,11.5,5.8,18.3,5.8c17.7,0,32-14.3,32-32c0-6.8-2.2-13.1-5.8-18.3L46.1,41z'
    />
  </svg>
);

export const BusIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M18,11H6V6H18M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M4,16C4,16.88 4.39,17.67 5,18.22V20A1,1 0 0,0 6,21H7A1,1 0 0,0 8,20V19H16V20A1,1 0 0,0 17,21H18A1,1 0 0,0 19,20V18.22C19.61,17.67 20,16.88 20,16V6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6V16Z'
    />
  </svg>
);

export const CloudIcon = React.memo(({size = 36, color = IconColors.Cloud}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,69H26.6c-0.5,0-0.9-0.1-1.3-0.2c-8-1.2-13.8-7.6-13.8-15.8c0-8.1,5.4-14.3,13.2-15.7c0.4-1.6,1-3.6,2-5.6c3.2-6.2,9-9.6,16.5-9.6c7.4,0,13.3,3.4,16.5,9.6c1,1.9,1.6,3.8,2,5.3h1.1c9.7,0,16.7,6.7,16.7,16C79.5,62.1,72.3,69,62.8,69z M28.2,61h34.6c6,0,8.7-4.1,8.7-8c0-4.8-3.5-8-8.7-8h-4.5c-2.1,0-3.9-1.7-4-3.8c0,0-0.2-3.1-1.8-6c-1.9-3.5-5-5.1-9.4-5.1C32.8,30,32.2,40,32.2,41.1c-0.1,2.2-1.8,3.9-4,3.9c-5.2,0-8.7,3.2-8.7,8C19.5,56.8,22.2,61,28.2,61z'
    />
  </svg>
);

export const FogIcon = React.memo(({size = 36, color = IconColors.Fog}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M18.7,32.1h47.8c2.2,0,4-1.8,4-4s-1.8-4-4-4H18.7c-2.2,0-4,1.8-4,4S16.5,32.1,18.7,32.1z M68,45.8c0-2.2-1.8-4-4-4H4.1c-2.2,0-4,1.8-4,4s1.8,4,4,4H64C66.2,49.8,68,48,68,45.8z M86.9,59.4H27c-2.2,0-4,1.8-4,4s1.8,4,4,4h59.9c2.2,0,4-1.8,4-4S89.1,59.4,86.9,59.4z M78.4,32.6h0.2c2.5,0,4.5-2,4.5-4.5c0-2.5-2-4.5-4.5-4.5h-0.2c-2.5,0-4.5,2-4.5,4.5C73.9,30.6,75.9,32.6,78.4,32.6z'
    />
  </svg>
);

export const HailCloudIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  hailColor = IconColors.Snow,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,26.7h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6c-7.8,1.4-13.2,7.6-13.2,15.7c0,5.2,2.5,10,6.6,12.9c1.8,1.3,4.3,0.9,5.6-0.9c1.3-1.8,0.9-4.3-0.9-5.6c-2-1.4-3.2-3.8-3.2-6.4c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9c0-1.1,0.6-11.1,10.9-11.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8c0,4-2.6,7-6.5,7.8c-2.2,0.4-3.6,2.5-3.2,4.7c0.4,1.9,2,3.2,3.9,3.2c0.3,0,0.5,0,0.8-0.1c7.7-1.5,13-7.9,13-15.7C79.5,33.5,72.5,26.7,62.8,26.7z'
    />
    <path
      fill={hailColor}
      d='M35.1,43.5c-1.9-0.5-3.8,0.6-4.3,2.5l-3.4,12.6c-0.5,1.9,0.6,3.8,2.5,4.3c0.3,0.1,0.6,0.1,0.9,0.1c1.5,0,3-1,3.4-2.6l3.4-12.6C38,45.9,36.9,44,35.1,43.5z M26,65.5c-0.7,0.6-1,1.5-1,2.5c0,0.9,0.4,1.8,1,2.5c0.7,0.7,1.6,1,2.5,1c0.9,0,1.8-0.4,2.5-1c0.7-0.6,1-1.5,1-2.5c0-0.9-0.4-1.8-1-2.5C29.6,64.2,27.3,64.2,26,65.5z M35.2,73.2c-0.7,0.6-1,1.5-1,2.5c0,0.9,0.4,1.8,1,2.5c0.6,0.6,1.5,1,2.5,1c0.9,0,1.8-0.4,2.5-1c0.6-0.6,1-1.5,1-2.5c0-0.9-0.4-1.8-1-2.5C38.9,71.9,36.5,71.9,35.2,73.2z M48.5,65.5c-0.6,0.6-1,1.6-1,2.5c0,0.9,0.4,1.8,1,2.5c0.7,0.7,1.6,1,2.5,1c0.9,0,1.8-0.4,2.5-1c0.7-0.6,1-1.5,1-2.5c0-0.9-0.4-1.8-1-2.5C52.2,64.2,49.8,64.2,48.5,65.5z M44.3,51.2c-1.9-0.5-3.8,0.6-4.3,2.5l-3.4,12.6c-0.5,1.9,0.6,3.8,2.5,4.3c0.3,0.1,0.6,0.1,0.9,0.1c1.5,0,3-1,3.4-2.6l3.4-12.6C47.3,53.6,46.2,51.7,44.3,51.2z M57.6,43.5c-1.9-0.5-3.8,0.6-4.3,2.5l-3.4,12.6c-0.5,1.9,0.6,3.8,2.5,4.3c0.3,0.1,0.6,0.1,0.9,0.1c1.5,0,3-1,3.4-2.6l3.4-12.6C60.6,45.9,59.5,44,57.6,43.5z'
    />
  </svg>
);

export const LightningCloudIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  boltColor = IconColors.Bolt,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,29.5h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6c-7.8,1.4-13.2,7.6-13.2,15.7c0,7.5,4.9,13.7,12.3,15.5c2.1,0.5,4.3-0.8,4.8-3c0.5-2.1-0.8-4.3-3-4.8c-3.7-0.9-6.1-3.9-6.1-7.7c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9c0-1.1,0.6-11.1,10.9-11.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8c0,3.9-2.7,8-8.7,8c-2.2,0-4,1.8-4,4s1.8,4,4,4c9.5,0,16.7-6.9,16.7-16C79.5,36.3,72.5,29.5,62.8,29.5z'
    />
    <path
      fill={boltColor}
      d='M52.2,54.6h-4.4l5.5-7.7c0.3-0.5,0.4-1.1,0.1-1.6c-0.3-0.5-0.8-0.8-1.3-0.8H41.1c-0.6,0-1.2,0.4-1.4,1l-6.1,15.8c-0.2,0.5-0.1,1,0.2,1.4c0.3,0.4,0.7,0.6,1.2,0.6h0l3.2,0l-4.2,11c-0.3,0.7,0,1.4,0.6,1.8c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.8-0.2,1.1-0.5l16.6-18.8c0.4-0.4,0.5-1.1,0.2-1.6S52.7,54.6,52.2,54.6z'
    />
  </svg>
);

export const LightningStormIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  boltColor = IconColors.Bolt,
  dropColor = IconColors.Rain,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,29.5h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6c-7.8,1.4-13.2,7.6-13.2,15.7c0,5.2,2.5,10,6.6,12.9c1.8,1.3,4.3,0.9,5.6-0.9c1.3-1.8,0.9-4.3-0.9-5.6c-2-1.4-3.2-3.8-3.2-6.4c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9c0-1.1,0.6-11.1,10.9-11.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8c0,4-2.6,7-6.5,7.8c-2.2,0.4-3.6,2.5-3.2,4.7c0.4,1.9,2,3.2,3.9,3.2c0.3,0,0.5,0,0.8-0.1c7.7-1.5,13-7.9,13-15.7C79.5,36.3,72.5,29.5,62.8,29.5z'/>
    <path
      fill={dropColor}
      d='M34.7,46.3c-1.9-0.5-3.8,0.6-4.3,2.5l-5.6,20.7c-0.5,1.9,0.6,3.8,2.5,4.3c0.3,0.1,0.6,0.1,0.9,0.1c1.5,0,3-1,3.4-2.6
      l5.6-20.7C37.7,48.7,36.6,46.8,34.7,46.3z'/>
    <path
      fill={boltColor}
      d='M57.1,54.6h-4.4l5.5-7.7c0.3-0.5,0.4-1.1,0.1-1.6c-0.3-0.5-0.8-0.8-1.3-0.8H46c-0.6,0-1.2,0.4-1.4,1l-6.1,15.8
      c-0.2,0.5-0.1,1,0.2,1.4c0.3,0.4,0.7,0.6,1.2,0.6h0l3.2,0l-4.2,11c-0.3,0.7,0,1.4,0.6,1.8c0.2,0.1,0.5,0.2,0.8,0.2
      c0.4,0,0.8-0.2,1.1-0.5l16.6-18.8c0.4-0.4,0.5-1.1,0.2-1.6C58.2,55,57.7,54.6,57.1,54.6z'/>
  </svg>
);

export const CloudSunIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  sunColor = IconColors.Sun,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M51.5,48.3h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6C5.6,50,0.2,56.2,0.2,64.3C0.2,72.4,6,78.8,14,80.1c0.4,0.1,0.9,0.2,1.3,0.2h36.2c9.5,0,16.7-6.9,16.7-16C68.2,55,61.2,48.3,51.5,48.3z M51.5,72.3H16.9c-6,0-8.7-4.1-8.7-8c0-4.8,3.5-8,8.7-8c2.1,0,3.9-1.7,4-3.9c0,0,0.1-3.1,1.7-6c1.8-3.4,4.9-5.1,9.2-5.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8C60.2,68.1,57.5,72.3,51.5,72.3z'
    />
    <path
      fill={sunColor}
      d='M74.3,42.8c0-8.6-7-15.6-15.6-15.6c-3.1,0-6.2,0.9-8.7,2.7c-1.4,0.9-1.7,2.8-0.8,4.2c0.9,1.4,2.8,1.7,4.2,0.8c1.6-1.1,3.5-1.6,5.4-1.6c5.3,0,9.6,4.3,9.6,9.6c0,1.6-0.4,3.1-1.1,4.4c-0.8,1.5-0.2,3.3,1.3,4c0.4,0.2,0.9,0.3,1.4,0.3c1.1,0,2.1-0.6,2.7-1.6C73.7,47.8,74.3,45.3,74.3,42.8z M58.7,22.4c1.7,0,3-1.3,3-3v-5.7c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3v5.7C55.7,21.1,57.1,22.4,58.7,22.4z M87.8,39.8H82c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3h5.7c1.7,0,3-1.3,3-3C90.8,41.1,89.4,39.8,87.8,39.8z M75.2,29.3c0.8,0,1.5-0.3,2.1-0.9l4-4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0l-4,4c-1.2,1.2-1.2,3.1,0,4.2C73.7,29,74.4,29.3,75.2,29.3z M77.3,57.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2l4,4c0.6,0.6,1.4,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2L77.3,57.2z M40.1,28.4c0.6,0.6,1.4,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2l-4-4c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L40.1,28.4z'
    />
  </svg>
);

export const CloudMoonIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  moonColor = IconColors.Moon,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M51.5,48.3h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6
        C5.6,50,0.2,56.2,0.2,64.3C0.2,72.4,6,78.8,14,80.1c0.4,0.1,0.9,0.2,1.3,0.2h36.2c9.5,0,16.7-6.9,16.7-16
        C68.2,55,61.2,48.3,51.5,48.3z M51.5,72.3H16.9c-6,0-8.7-4.1-8.7-8c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9
        c0-1.1,0.6-11.1,10.9-11.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8
        C60.2,68.1,57.5,72.3,51.5,72.3z'
    />
    <path
      fill={moonColor}
      d='M90.3,43.7c-0.6-0.9-1.7-1.4-2.7-1.3c-6.6,0.5-13-2.8-16.3-8.6c-3.3-5.7-3-12.9,0.7-18.4c0.6-0.9,0.7-2.1,0.2-3
        c-0.5-1-1.5-1.6-2.6-1.7c-4.6-0.2-9.2,0.9-13.3,3.3c-5.6,3.2-9.7,8.5-11.4,14.7c-0.4,1.6,0.5,3.3,2.1,3.7c1.6,0.4,3.2-0.5,3.7-2.1
        c1.3-4.7,4.4-8.7,8.6-11.1c1.6-0.9,3.3-1.6,5-2C62,23.6,62.6,30.8,66,36.8c3.5,6,9.5,10.1,16.1,11.3c-1.2,1.3-2.7,2.4-4.2,3.4
        c-1.4,0.8-3,1.5-4.5,1.9c-1.6,0.4-2.6,2.1-2.1,3.7c0.4,1.3,1.6,2.2,2.9,2.2c0.3,0,0.5,0,0.8-0.1c2.1-0.6,4.1-1.4,6-2.5
        c4-2.3,7.3-5.7,9.5-9.9C90.9,45.8,90.9,44.6,90.3,43.7z'
    />
  </svg>
);

export const RainCloudIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  rainColor = IconColors.Rain,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,29h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6c-7.8,1.4-13.2,7.6-13.2,15.7c0,5.8,2.9,10.9,7.9,13.8c1.9,1.1,4.4,0.4,5.5-1.5c1.1-1.9,0.4-4.4-1.5-5.5c-2.4-1.4-3.8-3.9-3.8-6.8c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9C32.2,32,32.8,22,43.1,22c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8c0,3.9-2.7,8-8.7,8h-2.2c-2.2,0-4,1.8-4,4s1.8,4,4,4h2.2c9.5,0,16.7-6.9,16.7-16C79.5,35.7,72.5,29,62.8,29z'
    />
    <path
      fill={rainColor}
      d='M44.4,61.2c-0.8,0-1.5,0.4-2,1c-0.9,1.2-4,5.5-4,8.5c0,3.9,3,6.3,6,6.3s6-2.4,6-6.3c0-3.1-3-7.3-4-8.5C45.9,61.5,45.2,61.2,44.4,61.2z M36.4,49.7c-0.8-1-2.4-1-3.2,0c-0.7,0.9-3,4.2-3,6.5c0,3,2.3,4.8,4.6,4.8s4.6-1.8,4.6-4.8C39.4,53.8,37.1,50.6,36.4,49.7z M50.5,43.8c-0.5,0-0.9,0.2-1.2,0.6c-0.6,0.8-2.6,3.6-2.6,5.6c0,2.5,1.9,4,3.8,4h0c1.9,0,3.8-1.5,3.8-4C54.3,48.7,52.2,43.8,50.5,43.8z'
    />
  </svg>
);

export const SnowCloudIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  snowColor = IconColors.Snow,
}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M62.8,28.4h-1.1c-0.4-1.6-1-3.4-2-5.3c-3.3-6.2-9.2-9.6-16.5-9.6c-7.4,0-13.3,3.4-16.5,9.6c-1,2-1.6,4-2,5.6c-7.8,1.4-13.2,7.6-13.2,15.7c0,4.4,1.7,8.4,4.7,11.4c1.6,1.5,4.1,1.5,5.7-0.1c1.5-1.6,1.5-4.1-0.1-5.7c-1.5-1.5-2.3-3.5-2.3-5.7c0-4.8,3.5-8,8.7-8c2.2,0,3.9-1.7,4-3.9c0-1.1,0.6-11.1,10.9-11.1c4.4,0,7.5,1.7,9.4,5.1c1.6,2.9,1.8,6,1.8,6c0.1,2.1,1.9,3.8,4,3.8h4.5c5.2,0,8.7,3.2,8.7,8c0,3.5-2,6.3-5.3,7.5c-2.1,0.7-3.2,3-2.5,5.1c0.6,1.7,2.1,2.7,3.8,2.7c0.4,0,0.9-0.1,1.3-0.2c6.5-2.2,10.6-8.1,10.6-15C79.5,35.1,72.5,28.4,62.8,28.4z'
    />
    <path
      fill={snowColor}
      d='M43.6,50.1c1.7,0,3-1.3,3-3v-2.8c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3v2.8C40.6,48.8,41.9,50.1,43.6,50.1z M43.6,55.4c-1.1,0-2.1,0.4-2.8,1.2c-0.7,0.7-1.2,1.8-1.2,2.8c0,1,0.4,2.1,1.2,2.8c0.7,0.8,1.8,1.2,2.8,1.2c1.1,0,2.1-0.4,2.8-1.2c0.8-0.7,1.2-1.8,1.2-2.8c0-1.1-0.4-2.1-1.2-2.8C45.7,55.8,44.6,55.4,43.6,55.4z M43.6,68.7c-1.7,0-3,1.3-3,3v2.8c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3v-2.8C46.6,70,45.3,68.7,43.6,68.7z M55.2,49.2l-2.5,1.4c-1.4,0.8-1.9,2.7-1.1,4.1c0.6,1,1.6,1.5,2.6,1.5c0.5,0,1-0.1,1.5-0.4l2.5-1.4c1.4-0.8,1.9-2.7,1.1-4.1C58.5,48.9,56.6,48.4,55.2,49.2z M31.5,63L29,64.4c-1.4,0.8-1.9,2.7-1.1,4.1c0.6,1,1.6,1.5,2.6,1.5c0.5,0,1-0.1,1.5-0.4l2.5-1.4c1.4-0.8,1.9-2.7,1.1-4.1C34.7,62.6,32.9,62.1,31.5,63z M58.2,64.4L55.7,63c-1.4-0.8-3.3-0.3-4.1,1.1c-0.8,1.4-0.3,3.3,1.1,4.1l2.5,1.4c0.5,0.3,1,0.4,1.5,0.4c1,0,2-0.5,2.6-1.5C60.1,67,59.6,65.2,58.2,64.4z M29,54.4l2.5,1.4c0.5,0.3,1,0.4,1.5,0.4c1,0,2-0.5,2.6-1.5c0.8-1.4,0.3-3.3-1.1-4.1L32,49.2c-1.4-0.8-3.3-0.3-4.1,1.1C27.1,51.8,27.6,53.6,29,54.4z'
    />
  </svg>
);

export const SleetCloudIcon = React.memo(({
  size = 36,
  color = IconColors.Cloud,
  snowColor = IconColors.Snow,
  rainColor = IconColors.Rain,
}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M1,11A5,5 0 0,1 6,6C7,3.65 9.3,2 12,2C15.43,2 18.24,4.66 18.5,8.03L19,8A4,4 0 0,1 23,12A4,4 0 0,1 19,16A1,1 0 0,1 18,15A1,1 0 0,1 19,14A2,2 0 0,0 21,12A2,2 0 0,0 19,10H17V9A5,5 0 0,0 12,4C9.5,4 7.45,5.82 7.06,8.19C6.73,8.07 6.37,8 6,8A3,3 0 0,0 3,11C3,11.85 3.35,12.61 3.91,13.16C4.27,13.55 4.26,14.16 3.88,14.54C3.5,14.93 2.85,14.93 2.47,14.54C1.56,13.63 1,12.38 1,11Z'
    />
    <path
      fill={snowColor}
      d='M4,17.36C3.86,16.82 4.18,16.25 4.73,16.11L7,15.5L5.33,13.86C4.93,13.46 4.93,12.81 5.33,12.4C5.73,12 6.4,12 6.79,12.4L8.45,14.05L9.04,11.8C9.18,11.24 9.75,10.92 10.29,11.07C10.85,11.21 11.17,11.78 11,12.33L10.42,14.58L12.67,14C13.22,13.83 13.79,14.15 13.93,14.71C14.08,15.25 13.76,15.82 13.2,15.96L10.95,16.55L12.6,18.21C13,18.6 13,19.27 12.6,19.67C12.2,20.07 11.54,20.07 11.15,19.67L9.5,18L8.89,20.27C8.75,20.83 8.18,21.14 7.64,21C7.08,20.86 6.77,20.29 6.91,19.74L7.5,17.5L5.26,18.09C4.71,18.23 4.14,17.92 4,17.36Z'
    />
    <path
      fill={rainColor}
      d='M18.5,18.67C18.5,19.96 17.5,21 16.25,21C15,21 14,19.96 14,18.67C14,17.12 16.25,14.5 16.25,14.5C16.25,14.5 18.5,17.12 18.5,18.67Z'
    />
  </svg>
);

export const SunIcon = React.memo(({size = 36, color = IconColors.Sun}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M45.5,23.5c-12.1,0-22,9.9-22,22c0,12.1,9.9,22,22,22c12.1,0,22-9.9,22-22C67.5,33.4,57.6,23.5,45.5,23.5z M45.5,59.5c-7.7,0-14-6.3-14-14c0-7.7,6.3-14,14-14c7.7,0,14,6.3,14,14C59.5,53.2,53.2,59.5,45.5,59.5z M45.5,16.2c2.2,0,4-1.8,4-4V4.1c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4v8.1C41.5,14.5,43.3,16.2,45.5,16.2z M86.9,41.5h-8.1c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4h8.1c2.2,0,4-1.8,4-4C90.9,43.3,89.1,41.5,86.9,41.5z M45.5,74.8c-2.2,0-4,1.8-4,4v8.1c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4v-8.1C49.5,76.5,47.7,74.8,45.5,74.8z M16.2,45.5c0-2.2-1.8-4-4-4H4.1c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4h8.1C14.5,49.5,16.2,47.7,16.2,45.5z M69,26c1,0,2-0.4,2.8-1.2l5.8-5.8c1.6-1.6,1.6-4.1,0-5.7c-1.6-1.6-4.1-1.6-5.7,0l-5.8,5.8c-1.6,1.6-1.6,4.1,0,5.7C67,25.6,68,26,69,26z M71.8,66.2c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7l5.8,5.8c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2c1.6-1.6,1.6-4.1,0-5.7L71.8,66.2z M19.2,66.2l-5.8,5.8c-1.6,1.6-1.6,4.1,0,5.7c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2l5.8-5.8c1.6-1.6,1.6-4.1,0-5.7C23.3,64.6,20.7,64.6,19.2,66.2z M19.2,24.8C19.9,25.6,21,26,22,26c1,0,2-0.4,2.8-1.2c1.6-1.6,1.6-4.1,0-5.7l-5.8-5.8c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7L19.2,24.8z'
    />
  </svg>
);

export const MoonIcon = React.memo(({size = 36, color = IconColors.Moon}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M47.2,78.1c-11.6,0-22.5-6.3-28.3-16.3c-9-15.6-3.6-35.6,11.9-44.6c5.3-3.1,11.5-4.6,17.6-4.3c1.5,0.1,2.8,0.9,3.4,2.2c0.7,1.3,0.5,2.9-0.3,4.1c-5,7.2-5.4,16.8-1,24.3c4.1,7,11.6,11.4,19.8,11.4c0.6,0,1.2,0,1.8-0.1c1.5-0.1,2.9,0.6,3.7,1.8c0.8,1.2,0.9,2.8,0.2,4.1c-2.9,5.5-7.2,10-12.6,13.1C58.5,76.6,52.9,78.1,47.2,78.1z M41.4,21.5c-2.3,0.5-4.5,1.4-6.6,2.6C23.1,31,19,46.1,25.8,57.8c4.4,7.6,12.6,12.3,21.4,12.3c4.3,0,8.5-1.1,12.3-3.3c2.1-1.2,4-2.7,5.6-4.4c-8.9-1.6-16.8-7-21.4-14.9C39.1,39.5,38.4,30,41.4,21.5z'
    />
  </svg>
);

export const WindIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M4,10A1,1 0 0,1 3,9A1,1 0 0,1 4,8H12A2,2 0 0,0 14,6A2,2 0 0,0 12,4C11.45,4 10.95,4.22 10.59,4.59C10.2,5 9.56,5 9.17,4.59C8.78,4.2 8.78,3.56 9.17,3.17C9.9,2.45 10.9,2 12,2A4,4 0 0,1 16,6A4,4 0 0,1 12,10H4M19,12A1,1 0 0,0 20,11A1,1 0 0,0 19,10C18.72,10 18.47,10.11 18.29,10.29C17.9,10.68 17.27,10.68 16.88,10.29C16.5,9.9 16.5,9.27 16.88,8.88C17.42,8.34 18.17,8 19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14H5A1,1 0 0,1 4,13A1,1 0 0,1 5,12H19M18,18H4A1,1 0 0,1 3,17A1,1 0 0,1 4,16H18A3,3 0 0,1 21,19A3,3 0 0,1 18,22C17.17,22 16.42,21.66 15.88,21.12C15.5,20.73 15.5,20.1 15.88,19.71C16.27,19.32 16.9,19.32 17.29,19.71C17.47,19.89 17.72,20 18,20A1,1 0 0,0 19,19A1,1 0 0,0 18,18Z'
    />
  </svg>
);

export const UmbrellaIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M12,2A9,9 0 0,0 3,11H11V19A1,1 0 0,1 10,20A1,1 0 0,1 9,19H7A3,3 0 0,0 10,22A3,3 0 0,0 13,19V11H21A9,9 0 0,0 12,2Z'
    />
  </svg>
);

export const HumidityIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M12,3.25C12,3.25 6,10 6,14C6,17.32 8.69,20 12,20A6,6 0 0,0 18,14C18,10 12,3.25 12,3.25M14.47,9.97L15.53,11.03L9.53,17.03L8.47,15.97M9.75,10A1.25,1.25 0 0,1 11,11.25A1.25,1.25 0 0,1 9.75,12.5A1.25,1.25 0 0,1 8.5,11.25A1.25,1.25 0 0,1 9.75,10M14.25,14.5A1.25,1.25 0 0,1 15.5,15.75A1.25,1.25 0 0,1 14.25,17A1.25,1.25 0 0,1 13,15.75A1.25,1.25 0 0,1 14.25,14.5Z'
    />
  </svg>
);

export const FullscreenIcon = React.memo(({
  size = 36,
  color = 'currentColor',
}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z'
    />
  </svg>
);

export const CalendarIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M7,10H12V15H7M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z'
    />
  </svg>
);

export const SunriseIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M45.5,26.3c2.2,0,4-1.8,4-4v-8.1c0-2.2-1.8-4-4-4s-4,1.8-4,4v8.1C41.5,24.5,43.3,26.3,45.5,26.3z M74.8,55.6c0,2.2,1.8,4,4,4h8.1c2.2,0,4-1.8,4-4s-1.8-4-4-4h-8.1C76.5,51.6,74.8,53.4,74.8,55.6z M4.1,59.6h8.1c2.2,0,4-1.8,4-4s-1.8-4-4-4H4.1c-2.2,0-4,1.8-4,4S1.9,59.6,4.1,59.6z M69,36.1c1,0,2-0.4,2.8-1.2l5.8-5.8c1.6-1.6,1.6-4.1,0-5.7c-1.6-1.6-4.1-1.6-5.7,0l-5.8,5.8c-1.6,1.6-1.6,4.1,0,5.7C67,35.7,68,36.1,69,36.1z M19.2,34.9c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2c1.6-1.6,1.6-4.1,0-5.7l-5.8-5.8c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7L19.2,34.9z M25.5,64.7c0.9,2,3.3,2.9,5.3,2c2-0.9,2.9-3.3,2-5.3c-0.8-1.8-1.3-3.8-1.3-5.8c0-7.7,6.3-14,14-14s14,6.3,14,14c0,2-0.4,4-1.3,5.8c-0.9,2,0,4.4,2,5.3c0.5,0.2,1.1,0.4,1.7,0.4c1.5,0,3-0.9,3.6-2.3c1.3-2.9,2-6,2-9.1c0-12.1-9.9-22-22-22c-12.1,0-22,9.9-22,22C23.5,58.8,24.2,61.8,25.5,64.7z M86.9,72.8H4.1c-2.2,0-4,1.8-4,4s1.8,4,4,4h82.8c2.2,0,4-1.8,4-4S89.1,72.8,86.9,72.8z M43.4,48.5l-6.1,6.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0l4-4l4,4c0.6,0.6,1.4,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2l-6.1-6.1C46.4,47.4,44.6,47.4,43.4,48.5z'
    />
  </svg>
);

export const SunsetIcon = React.memo(({size = 36, color = 'currentColor'}) =>
  <svg width={size} height={size} viewBox='0 0 91 91'>
    <path
      fill={color}
      d='M45.5,26.3c2.2,0,4-1.8,4-4v-8.1c0-2.2-1.8-4-4-4s-4,1.8-4,4v8.1C41.5,24.5,43.3,26.3,45.5,26.3z M74.8,55.6c0,2.2,1.8,4,4,4h8.1c2.2,0,4-1.8,4-4s-1.8-4-4-4h-8.1C76.5,51.6,74.8,53.4,74.8,55.6z M4.1,59.6h8.1c2.2,0,4-1.8,4-4s-1.8-4-4-4H4.1c-2.2,0-4,1.8-4,4S1.9,59.6,4.1,59.6z M69,36.1c1,0,2-0.4,2.8-1.2l5.8-5.8c1.6-1.6,1.6-4.1,0-5.7c-1.6-1.6-4.1-1.6-5.7,0l-5.8,5.8c-1.6,1.6-1.6,4.1,0,5.7C67,35.7,68,36.1,69,36.1z M19.2,34.9c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2c1.6-1.6,1.6-4.1,0-5.7l-5.8-5.8c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7L19.2,34.9z M25.5,64.7c0.9,2,3.3,2.9,5.3,2c2-0.9,2.9-3.3,2-5.3c-0.8-1.8-1.3-3.8-1.3-5.8c0-7.7,6.3-14,14-14s14,6.3,14,14c0,2-0.4,4-1.3,5.8c-0.9,2,0,4.4,2,5.3c0.5,0.2,1.1,0.4,1.7,0.4c1.5,0,3-0.9,3.6-2.3c1.3-2.9,2-6,2-9.1c0-12.1-9.9-22-22-22c-12.1,0-22,9.9-22,22C23.5,58.8,24.2,61.8,25.5,64.7z M86.9,72.8H4.1c-2.2,0-4,1.8-4,4s1.8,4,4,4h82.8c2.2,0,4-1.8,4-4S89.1,72.8,86.9,72.8z M49.5,51.5l-4,4l-4-4c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2l6.1,6.1c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9l6.1-6.1c1.2-1.2,1.2-3.1,0-4.2C52.6,50.4,50.7,50.4,49.5,51.5z'
    />
  </svg>
);

export const CompassIcon = React.memo(({
  size = 36,
  color = 'currentColor',
  angle = 0,
}) =>
  <svg
    width={size}
    height={size}
    style={{transform: `rotate(${angle}deg)`}}
    viewBox='0 0 24 24'
  >
    <path
      fill={color}
      d='M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z'
    />
  </svg>
);

export const ChevronLeftIcon = React.memo(({
  size = 36,
  color = 'currentColor',
}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z'
    />
  </svg>
);

export const ChevronRightIcon = React.memo(({
  size = 36,
  color = 'currentColor',
}) =>
  <svg width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={color}
      d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'
    />
  </svg>
);
