import React from 'react';
import {padZero2} from './date-util';
import {MonthsShort, WeekdaysLong} from './format';
import './clock.css';

export const Clock = ({now}) =>
  <div className='clock'>
    <div className='clock__time'>
      <span className='clock__hours'>
        {padZero2(now.getHours())}
      </span>
      :
      <span className='clock__minutes'>
        {padZero2(now.getMinutes())}
      </span>
      <span className='clock__seconds'>
        {padZero2(now.getSeconds())}
      </span>
    </div>
    <div className='clock__date'>
      <div className='clock__weekday'>
        {WeekdaysLong[now.getDay()]}
      </div>
      <div className='clock__date-value'>
        <span className='clock__day'>
          {padZero2(now.getDate())}
        </span>
        <span className='clock__month'>
          {MonthsShort[now.getMonth()]}
        </span>
        <span className='clock__year'>
          {now.getFullYear()}
        </span>
      </div>
    </div>
  </div>;
