const URL = 'https://info-screen.heurl.in/api/weather.php';

export class WeatherService {
  constructor() {
    this.requestNumber = 0;
    this.parseHourly = this.parseHourly.bind(this);
    this.parseSunTime = this.parseSunTime.bind(this);
  }

  update() {
    const requestNumber = ++this.requestNumber;
    return fetch(URL)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }
        if (this.requestNumber !== requestNumber) {
          throw new Error('Interrupted by another request');
        }
        return this.parse(data);
      });
  }

  parse(data) {
    return {
      ...data,
      hourly: data.hourly.map(this.parseHourly),
      sunTimes: data.sunTimes.map(this.parseSunTime),
    };
  }

  parseHourly(data) {
    return {
      ...data,
      time: new Date(data.time),
    };
  };

  parseSunTime(data) {
    return {
      ...data,
      time: new Date(data.time),
    };
  }
}
