const SECOND = 1000;
const MINUTE = 60 * SECOND;

const isWorkingDay = date => date.getDay() >= 1 && date.getDay() <= 5;

const isRushHour = date =>
  isWorkingDay(date) &&
  date.getHours() >= 7 && date.getHours() <= 9;

const isSlowHour = date =>
  date.getHours() >= 1 && date.getHours() <= 6;

export const Intervals = Object.freeze({
  idleTimeout: 2 * MINUTE,

  getTransportationUpdateFrequency: date =>
    isRushHour(date) ? 30 * SECOND :
    isSlowHour(date) ? 2 * MINUTE :
    MINUTE,

  getWeatherUpdateFrequency: date =>
    isSlowHour(date)
      ? 30 * MINUTE
      : 15 * MINUTE,

  getDefaultSection: date =>
    isWorkingDay(date) &&
    date.getHours() >= 6 && date.getHours() <= 9
      ? 'transportation'
      : 'weather',
});
